import { http, Problem } from '../utils/httpRequest';

/**
 * 根据手机号获取验证码
 * */
export function getSmsSendCode(data) {
  return http({
    url: '/api/sms/send',
    method: 'POST',
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
}

/*
* 手机号验证码登录
* */
export function submitMobileLogin(data) {
  return http({
    url: '/api/user/mobilelogin',
    method: 'POST',
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
}

/*
* 手机号密码登录
* */
export function submitPassordLogin(data) {
  return http({
    url: '/api/user/login',
    method: 'POST',
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
}

/**
 * 获取扫码登录code
 * */
export function getLoginCode() {
  return http({
    url: '/api/user/getLoginCode',
    method: 'GET'
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
}
/**
 * 校验扫码登录状态
 * */
export function checkLoginCode(code) {
  return http({
    url: `/api/user/checkLoginCode?code=${code}`,
    method: 'GET'
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
}

/**
 * 手机扫码登录
 * */
export function submitCodeLogin(code) {
  return http({
    url: `/api/user/codeLogin?code=${code}`,
    method: 'GET',
    header: true
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
}

/**
 * 三方登录
 * */
export function submitThirdLogin(data) {
  return http({
    url: '/api/user/third',
    method: 'POST',
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
}

/**
 * 三方登录 手机号绑定
 * */
export function bindThirdMobile(data) {
  return http({
    url: '/api/user/third_bind_mobile',
    method: 'POST',
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
}

/**
 * 获取用户信息
 * */
export function getUserInfo() {
  return http({
    url: '/api/user/userinfo',
    method: 'POST'
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
}
