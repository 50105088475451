<template>
  <div />
</template>

<script>
import * as loginService from '@/service/login-service';
import * as Utils from '@/utils';
import { Message } from 'element-ui';
export default {
  name: 'wechat',
  mounted() {
    const code = this.$route.query.code;
    if (code) {
      this.submitThirdLogin(code);
    }
  },
  methods: {
    submitThirdLogin(code) {
      const data = {
        platform: 'wechat',
        code: code
      };
      loginService.submitThirdLogin(data).then(res => {
        if (res.code === 1) {
          // 登录成功
          if (res.data.code === 1) {
            Utils.setLocalStorage('LoginState', true);
            Utils.setLocalStorage('LoginUser', res.data.userinfo);
            const path = this.$route.query.redirect ? this.$route.query.redirect : '/';
            this.$router.replace(path);
            Message({
              message: '登录成功',
              type: 'success',
              duration: 1500,
              customClass: 'element-error-message-zindex'
            });
          }
          // 绑定手机号
          if (res.data.code === 2) {
            this.$router.replace({
              path: '/bindMobile',
              query: {
                thirdId: res.data.third_id
              }
            });
          }
        }
        else {
          // this.$router.go(-1);
        }
      });
    }
  }
};
</script>

<style scoped>

</style>
